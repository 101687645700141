<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
    <v-tabs v-model="tabIndex">
      <v-tab v-for="item in items" :key="item.id">{{ item.tab }}</v-tab>
    </v-tabs>
    <v-tabs-items class="tab-content" v-model="tabIndex">
      <v-tab-item active>
        <subject-content
          class="tab-detail"
          :subject="detailSubject"
          :books="listBooks"
          :isNew="isCreate"
          @list_book_ids="list_book_ids = $event"
        ></subject-content>
      </v-tab-item>
    </v-tabs-items>
    <v-row v-if="isCreate" class="align-center justify-center ma-0 pt-5">
      <v-btn color="primary" @click="handleSubmit(saveSubject)">Tạo môn học</v-btn>
    </v-row>
    <v-row v-else class="align-center justify-center ma-0 pt-5">
      <v-btn color="primary" @click="handleSubmit(updateSubject)">Cập nhật môn học</v-btn>
    </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import SubjectContent from "@/components/subject/SubjectContent.vue";

import {mapGetters, mapState} from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_LOADING } from "@/store/loading.module";
import {
  GET_DETAIL_SUBJECT,
  UPDATE_SUBJECT,
  SAVE_SUBJECT,
  SET_DEFAULT_SUBJECT,
} from "@/store/subject.module";
import { SUCCESS, ERROR } from "@/store/alert.module";
import {GET_LIST_BOOK} from "@/store/book.module";
import TCategoryContent from "@/components/category/TCategoryContent.vue";

export default {
  data() {
    return {
      id: "",
      isCreate: true,
      tabIndex: 0,
      subject: {},
      list_book_ids: [],
      items: [{ id: 0, tab: "Thông tin chủ đề" }],
      headers: [
        {
          text: "id",
          value: "id",
        },
        { text: "Tên Chuyên mục", value: "name" },
      ],
    };
  },
  components: {
    TCategoryContent,
    SubjectContent,
  },
  computed: {
    ...mapState({
      detailSubject: (state) => state.subject.subject,
    }),
    ...mapGetters({
      listBooks: "listBooks",
    }),
  },
  watch: {
    detailSubject: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.subject = newVal;
      },
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.$store.dispatch(GET_LIST_BOOK,{active: true})
    this.$store.dispatch(SET_DEFAULT_SUBJECT, [{ title: "Sửa môn học" }]);
    if (this.id) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sửa môn học" }]);

      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(GET_DETAIL_SUBJECT, { id: this.id })
        .then(() => {
          this.$store.dispatch(SET_LOADING, false);
        })
        .catch(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
    } else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thêm môn học" }]);
    }
  },
  methods: {
    updateSubject() {
      let payload = {
        id: this.subject.id,
        name: this.detailSubject.name,
        act: this.detailSubject.status ? 1 : 0,
        ord: this.detailSubject.order,
        img:this.detailSubject.img,
        book_id: JSON.stringify(this.list_book_ids),
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(UPDATE_SUBJECT, payload)
        .then((data) => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Cập nhật môn học thành công!", {
              root: true,
            });
            this.$router.go(-1);
          } else {
            if(data.errorCode == 424){
              this.$store.dispatch(ERROR, data.message, {
                root: true,
              });
            }else {
              this.$store.dispatch(ERROR, data.message, {
                root: true,
              });
            }
          }
          this.$store.dispatch(SET_LOADING, false);
        })
        .catch(() => {
          this.$store.dispatch(ERROR, "Cập nhật môn học thất bại!", {
            root: true,
          });
          this.$store.dispatch(SET_LOADING, false);
        });
    },
    saveSubject() {
      let payload = {
        name: this.detailSubject.name,
        act: this.detailSubject.status ? 1 : 0,
        ord: 2,
        book_id: JSON.stringify(this.list_book_ids),
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(SAVE_SUBJECT, payload)
        .then((data) => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Thêm môn học thành công!", {
              root: true,
            });
            this.$router.go(-1);
          } else {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }
          this.$store.dispatch(SET_LOADING, false);
        })
        .catch(() => {
          this.$store.dispatch(ERROR, data.message, { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
    },
  },
};
</script>

<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
