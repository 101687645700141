<template>
  <v-container>
    <v-row class="align-center justify-center">

      <v-col cols="2" class="pa-1">
        <span> Tên bài chủ đề </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="detailCategory.name"
              @input="updateName"
              v-model="detailCategory.name"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailCategory.slug"
            v-model="detailCategory.slug"
            @input="changeSlug"
        ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả chuyên mục </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ck-content
            id="lesson_content"
            :content="detailCategory.short_content"
            @getData="updateContent"
            v-model="detailCategory.short_content"
        ></ck-content>

      </v-col>
    </v-row>
    <!--    <v-row class="align-center justify-center">-->
    <!--        <v-col cols="2" class="pa-1">-->
    <!--          <span> Số bài viết </span>-->
    <!--        </v-col>-->
    <!--        <v-col cols="10" class="pa-1">-->
    <!--          <span> {{ detailCategory.news_news_category_count }} </span>-->
    <!--        </v-col>-->
    <!--      </v-row>-->

    <v-row class="align-center justify-center mt-2 mb-2">
      <v-col cols="2" class="pa-1">
        <span> Chủ đề cha </span>
      </v-col>
      <v-col :cols="detailCategory.parent ? '6':'8'" class="pa-1">
          <span>
            {{ detailCategory.parent ? detailCategory.parent.name : "" }}
          </span>
      </v-col>
      <v-col cols="2" class="pa-1" v-if="detailCategory.parent">
        <v-btn color="#1565C0" outlined @click="deleteCategoryParent"
        >Xóa chủ đề cha
        </v-btn
        >
      </v-col>
      <v-col cols="2" class="pa-1">
        <v-btn color="primary" @click="updateParent"
        >Cập nhật chủ đề cha
        </v-btn
        >
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Lớp học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-select
            v-model="detailCategory.grade"
            :items="detailCategory.grades"
            item-text="name"
            item-value="id"
            dense
            label="Nhập lớp học"
            return-object
            outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Sách </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-select
            v-model="list_book_ids"
            :items="books"
            item-text="name"
            item-value="id"
            dense
            multiple
            label="Nhập sách"
            outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Môn học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-select
            v-model="detailCategory.subject"
            :items="detailCategory.subjects"
            item-text="name"
            item-value="id"
            label="Nhập môn học"
            return-object
            dense
            outlined
        ></v-select>
      </v-col>
    </v-row>


    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-switch v-model="detailCategory.status"></v-switch>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="order"
            rules="number"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="convertNumberToString(detailCategory.order)"
              @input="update"
              v-model="detailCategory.order"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    <!--
  <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
          <span> Ngày tạo </span>
      </v-col>
      <v-col cols="10" class="pa-1">
          <hnr-input :text="detailCategory.create_time" :readonly="true" v-model="detailCategory.create_time"></hnr-input>
      </v-col>
  </v-row>

  <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
          <span> Ngày sửa </span>
      </v-col>
      <v-col cols="10" class="pa-1">
          <hnr-input :text="detailCategory.update_time" :readonly="true" v-model="detailCategory.update_time"></hnr-input>
      </v-col>
  </v-row> -->

    <v-dialog v-model="dialog" max-width="600px" min-height="600px">
      <v-card>
        <v-card-title class="text-center">
          <span class="headline">Thay đổi chủ đề cha</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="align-start justify-center ma-0">
              <v-col cols="8">
                <!-- <v-autocomplete
                v-model="value"
                :items="suggestNewCategories"
                item-value='id'
                item-text='name'
                label="Chọn chủ đề cho bài viết"
                :search-input.sync="search"
              >
              </v-autocomplete> -->
                <hnr-input :text="search" v-model="search" @active="searchCategoriesByName"></hnr-input>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" @click="searchCategoriesByName"
                >Tìm kiếm
                </v-btn
                >
              </v-col>

            </v-row>
            <!--              <v-row>-->
            <!--                <v-col cols="2" class="pa-0 ma-0 ml-5">-->
            <!--                  <v-btn color="primary" @click="setRootParent"-->
            <!--                  >Đặt làm chủ đề gốc</v-btn-->
            <!--                  >-->
            <!--                </v-col>-->
            <!--              </v-row>-->
            <v-row class="align-start justify-center ma-0">
              <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="suggestNewCategories"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
              >
              </v-data-table>
            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="saveNewsCategory"
              >Lưu chủ đề cha
              </v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import HnrInput from "@/components/elements/hnr-input";
// import HnrAreaInput from '@/components/elements/hnr-area-input'
import CkContent from '@/components/ck-content'
import {SET_LOADING} from "@/store/loading.module";
import {SEARCH_TOPIC_BY_NAME,} from "@/store/topic.module";
import mixin from "@/store/mixin";

export default {
  mixins: [mixin],
  props: {
    category: Object,
    books: Array,
    isNew: {type: Boolean, default: false},
  },
  computed: {
    ...mapState({
      nameOfNewsCategories: (state) => state.newscategory.nameOfNewscategories,
    }),
  },
  watch: {
    category: {
      handler(newVal) {
        if (newVal) {
          this.detailCategory = newVal;
          if (newVal?.book_id && newVal.book_id.length > 0) {
            newVal.book_id.forEach((item) => {
                  this.list_book_ids.push(item.id)
                }
            )
          }
        }
      },
    },
    list_book_ids: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('list_book_ids', newVal)
        }
      },
    },
    detailCategory: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (this.isNew && newVal) {
          this.detailCategory.slug = this.sanitizeTitle(newVal.name);
        }
      },
    },
    nameOfNewsCategories: {
      deep: true,
      handler(newVal) {
        this.suggestNewCategories = newVal;
      },
    },
  },
  data() {
    return {
      detailCategory: {
        name: "",
        slug: "",
      },
      slug: "",
      dialog: false,
      suggestNewCategories: [],
      // selectedCategories: [],
      value: 0,
      search: null,
      singleSelect: true,
      selected: [],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      list_book_ids: [],
    };
  },
  components: {
    HnrInput,
    //   HnrAreaInput,
    CkContent
  },
  methods: {
    changeSlug() {
      this.detailCategory.slugHasChanged = true;
    },
    updateContent(val) {
      this.detailCategory.short_content = val;
    },
    searchCategoriesByName() {
      if (this.search) {
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(SEARCH_TOPIC_BY_NAME, {name: this.search})
            .then(() => {
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(SET_LOADING, false);
            });
      }
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.detailCategory.parent = {
          id: this.selected[0].id,
          name: this.selected[0].name,
        };
      } else {
        this.detailCategory.parent = {
          id: null,
          name: null,
        };
      }
      this.dialog = false;
    },
    onClick() {
    },
    updateName() {
      //generate slug
      this.$emit("input");
    },
    updateParent() {
      this.dialog = true;
    },
    setRootParent() {
      this.dialog = false;
      this.detailCategory.parent = null;
    },
    update() {
      this.$emit("input");
    },
    convertNumberToString(number) {
      if (number != undefined || number != null) {
        return number.toString();
      }
      return "0";
    },
    deleteCategoryParent() {
      if (this.detailCategory.parent_id) {
        this.detailCategory.parent_id = null;
        this.detailCategory.parent = null;
      }
    }
  },
};
</script>

<style lang="scss">
</style>
