<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên môn học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <hnr-input :text="subject.name" v-model="subject.name"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="status" rules="required" v-slot="{ errors }">
          <v-switch v-model="subject.status"></v-switch>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>

      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(subject.order)"
            v-model="subject.order"
        ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Chọn sách </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-select
            v-model="list_book_ids"
            :items="books"
            item-text="name"
            item-value="id"
            multiple
            chips
            dense
            outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Banner ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            class="pl-1"
            prepend-icon="mdi-camera"
            v-model="image"
            label="Tải ảnh"
            @change="onChangeFileUpload"
            @click:clear="clearImage"
        ></v-file-input>
        <div id="preview">
          <v-img :src="replace_image"></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import {SAVE_IMAGE} from "@/store/image.module";

export default {
  props: {
    subject: Object,
    books: Array,
  },
  components: {
    HnrInput,
  },
  data() {
    return {
      image: {},
      show: true,
      list_book_ids: []
    };
  },
  computed: {
    replace_image() {
      if (this.subject) {
        return process.env.VUE_APP_BASE_URL + "/" + this.subject.img;
      }
      return "";
    },
  },
  watch: {
    subject: {
      handler(newVal) {
        if (newVal) {
          if (newVal?.book_id && newVal.book_id.length > 0) {
            newVal.book_id.forEach((item) => {
                  this.list_book_ids.push(item.id)
                })
          }
        }
      },
    },
    list_book_ids: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$emit('list_book_ids', newVal)
        }
      },
    },
  },
  methods: {
    convertNumberToString(number) {
      if (number !== undefined) {
        return number.toString();
      }
      return "0";
    },
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(SAVE_IMAGE, formData).then((data) => {
        if (data) {
          this.subject.img = data;
        }
      });
    },
    clearImage() {
      this.subject.img = "";
      this.show = false;
    },
  },
};
</script>